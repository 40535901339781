body {
    background-color: #D7E9FA;
    display: flex;
    justify-content: center;
    align-items: center;
}


.goButton {
    background-color: rgb(191, 191, 191);
    border: none;
    border-radius: 12px;
    color: white;
    font-size: medium;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-top: 3vh;
    position: relative
}

.goButton:hover {
    background-color: rgb(168, 168, 168);
}