.viewer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;

    margin: auto;
    height: 80vh;
    width: 75vw;

    background-color: white;
    border-radius: 25px;
    box-shadow: 0 0 5px rgba(49, 62, 73, 0.5);
}

.back {
    background-color: #9CCADE; 
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
    font-size: medium;
    position: absolute;
    right: 5.75vw;
    bottom: 5vh;
}

.back:hover {
    background-color: #88b3c6; 
}
