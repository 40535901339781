.image {
  margin: auto;
  max-height: 40vh;
}

.image_container {
  position: relative;
  max-width: 50vw;
  max-height: 40vh;
  margin: auto;
}

.control_buttons {
  padding-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.control_buttons button {
  margin-bottom: 2vh;
  margin-right: 0.5vh;
  background-color: rgb(178, 178, 178);
  border: none;
  border-radius: 0.5rem;
  color: white;
  height: 4vh;

  display: flex;
  align-items: center;
  justify-content: center;

}

.control_buttons button:hover {
  background-color: rgb(142, 142, 142);
}

.ZoomIn, .ZoomOut {
  font-size: large;
}
  
  