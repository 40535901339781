.container {
    background-color: #EDEEEF;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-radius: 12px;
    width: 65vw;
    height: 55vh;
}

.img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
