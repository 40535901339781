.text_container {
    padding-top: 5vh;
}

.text_viewer {
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    position: relative;
    max-height: 45vh;
    overflow-y: scroll;
    text-align: left;
  }
  

.copy_button {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem; 
    padding-left: 0.5rem;
    padding-right: 0.5rem; 
    margin:0.5rem; 
    border-radius: 0.375rem; 
    border-width: 2px; 
    color: #ffffff; 
    --bg-opacity: 0.5; 
  }
  